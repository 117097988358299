// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
@import '@angular/material/theming';
// Plus imports for other components in your app.
@import "ag-grid-community/dist/styles/ag-grid.scss";
@import "ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import '~@datamaster/styles/_variables.scss';
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$dm-test2-primary: mat.define-palette(mat.$indigo-palette);
$dm-test2-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$dm-test2-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$dm-test2-theme: mat.define-light-theme((
  color: (
    primary: $dm-test2-primary,
    accent: $dm-test2-accent,
    warn: $dm-test2-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($dm-test2-theme);

/* You can add global styles to this file, and also import other style files */
@import url('https://fonts.googleapis.com/css?family=Lato|Montserrat|Open+Sans:300,400,500,600|Roboto|Merriweather+Sans:300,400,500,600|Titillium+Web:300,400,500,600,700');



//@if $theme == light {
//  $body-bg-color: #f2f2ea;
//}

html {
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0;
}

body {
  background-color: #f2f6fa;
  color: #212529;
  font-family: "Open Sans", Arial, Helvetica, sans-serif;
  font-size: 16px;
  height: 100%;
  /*display: flex;
  flex-direction: column;*/
}

app-root {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

// Body container
.body-container {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding: 48.66px 0 0; //48.66px represents the height of the header

  .sidebar {
    //background-color: #102a43;
    background-color: rgba(16, 42, 67, 0.85);
    position: relative;
    //height: 100%;
    min-width: 62px;
  }

  &.classic {
    padding-top: 78px!important;
  }

  .body-content {
    flex: 1;
    position: relative;
  }

}

@media (min-width: 1400px) {
  .container {
    //margin-right: calc(#{$news-widget-width} - 60px);
    max-width: 1400px
  }
}


.app-loading-child{
  display: flex;
  justify-content: inherit;
  align-items: inherit;
  }
  .spinner-text{
      color: #404040 !important;
      font-weight: 600;
      font-family: 'Montserrat';
      font-size: 24px;
      margin-top: 15px;
    }

    // app-loading spinner
  // Taken from https://medium.com/@tomastrajan/how-to-style-angular-application-loading-with-angular-cli-like-a-boss-cdd4f5358554
  .app-loading {
      align-items: center;
      //display: flex;
      //flex-direction: column;
      //height: 100%;
      justify-content: center;
      //margin-top: 100px;
      //position: relative;

      .logo {
        background: url(./assets/images/dm-icon.png) center center no-repeat;
        //background: url(../../../../assets/images/dm-icon.png) center center no-repeat;
        background-size: contain;
        height: 100px;
        width: 100px;
        position: absolute;
        //left: 50px;
        //bottom: -25px;
      }

      .spinner {
        animation: rotate 2s linear infinite;
        //bottom: 52px;
        height: 200px;
        //left: 0;
        margin: auto;
        //position: absolute;
        //right: 0;
        //top: 0;
        transform-origin: center center;
        //width: 200px;

        .path {
          animation: dash 1.5s ease-in-out infinite;
          stroke-dasharray: 1, 200;
          stroke-dashoffset: 0;
          stroke: #ddd;
          stroke-linecap: round;
        }
      }


    }

.my-row {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  //width: 100%;
}

.my-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }

  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }

  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.backdrop {
    background: white;
    opacity: 0.85 !important;
  }
}

.cdk-overlay-container{
  z-index: 1060;
}

.cdk-overlap-pane{
  min-width: 200px;
}


.mat-dialog-container{
  padding: 0 !important;
}

.mat-dialog-title{
  margin: 0 0 10px !important;
}

.dialog-footer{
  background-color: #017dad;
  height: 60px;
  padding: 0.5rem !important;
  margin: 0;
}
.dialog-footer>*{
  margin: 0.25rem;
}

.setting-group{
  margin-top: 15px;
  margin-bottom: 15px;

}

.setting-group-heading{
  margin-left: 5px;
  margin-bottom: 5px;
}

.setting-drop-down-label{
  margin-right: 10px;
}

.mat-dialog-container{
  overflow: unset !important;//helps size the modal to fit
}

.btn-cancel{
  background-color: $admin-gray-color;
}

.section-title {
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
}

.ag-theme-alpine {
  @include ag-theme-alpine((
    header-background-color: $admin-dark-gray-color,
    odd-row-background-color: #e6e6e6,
    row-height: 30px,
    header-height: 36px
  ));
}

.ag-header-cell {
  color: white;
}

