
$body-bg-color: #f2f6fa;
$admin-blue-color : #017dad;
$admin-blue-hover: #016d98;
$admin-blue-border: #015b7f;
$admin-dark-gray-color : #545454;
$admin-light-gray-color : #D2D2D2;
$admin-gray-color : #808080;
// Nav vars
//$header-height: 81px; // height 61 with 20px margin-bottom
$header-height: 68px;   // height 48 with 20 margin-bottom;
$subheader-height: 35px;

// Footer vars
$footer-height: 30px;
$footer-margin: 20px;
